import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.div`
    background-color: whitesmoke;
    font-size: 0.85rem;
    padding: 2rem;

    text-align: center;
`;

const Footer = () => {
    return (
        <div
            style={{
                backgroundColor: "white",
                paddingTop: "50px",
            }}
        >
            <FooterWrapper>
                <div className="width-20 t:center t:block">
                    LM Recicladora S.A de C.V &copy; Todos los Derechos
                    Reservados - {new Date().getFullYear()}
                </div>
                <div className="flex flex:center">
                    <make-digital></make-digital>
                </div>
            </FooterWrapper>
        </div>
    );
};

export default Footer;
