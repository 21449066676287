import React from "react";
import Seo from "./seo";
import Navigation from "./navbar";
import Footer from "./../components/footer";
import useWidth from "../hooks/useWidth";

const Layout = ({ children }) => {
    const { isMobile } = useWidth();

    return (
        <section>
            <Seo />
            {!isMobile && <Navigation />}
            {children}
            <Footer />
        </section>
    );
};

export default Layout;
