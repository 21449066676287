import { useLayoutEffect, useState, useCallback } from "react";

const useWidth = () => {
	const [dimentions, setDimentions] = useState({
		width: 0,
		height: 0,
		isMobile: false
	});

	const getDimensions = useCallback(() => {
		const width = window.innerWidth;
		const height = window.innerHeight;

		return {
			width,
			height,
			isMobile: width < 768
		};
	}, []);

	useLayoutEffect(() => {
		function handleResize() {
			const dimentions = getDimensions();
			setDimentions(dimentions);
		}

		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [getDimensions]);

	return dimentions;
};

export default useWidth;
