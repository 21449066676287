import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ description, keywords, author, title, url, image }) => {
    const { site } = useStaticQuery(detailsQuery);
    const metaDescription = description || site.siteMetadata.description;
    const metaTitle = title || site.siteMetadata.title;
    const metaUrl = url || site.siteMetadata.url;
    const metaImage = image || site.siteMetadata.image;
    const metaAuthor = author || site.siteMetadata.author;
    const metaKeywords = keywords || site.siteMetadata.seokeywords;

    const data = {
        title: metaTitle,
        description: metaDescription,
        url: metaUrl,
        image: metaImage,
        keywords: metaKeywords,
        author: metaAuthor,
        lang: "es",
    };

    return (
        <Helmet
            htmlAttributes={{ lang: data.lang }}
            title={data.title}
            meta={[
                {
                    name: "author",
                    content: data.author,
                },
                {
                    name: "description",
                    content: data.description,
                },
                {
                    name: "keywords",
                    content: data.keywords.length
                        ? data.keywords.join(", ")
                        : "",
                },
                {
                    property: "og:title",
                    content: data.title,
                },
                {
                    property: "og:description",
                    content: data.description,
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    property: "og:url",
                    content: data.url,
                },
                {
                    property: "og:image",
                    content: data.image,
                },
            ]}
        />
    );
};

const detailsQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                description
                seokeywords
                author
                image
                url
            }
        }
    }
`;

export default Seo;
