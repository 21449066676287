import React from "react";
import styled from "styled-components";

const Link = styled.a`
    display: inline-flex;
    text-transform: uppercase;
    padding: 1rem 2rem;

    &,
    &:hover {
        text-decoration: none;
        color: black;
    }
`;

const Nav = styled.div`
    border-bottom: 1px solid black;
`;

const Navigation = () => {
    return (
        <div className="flex flex:center">
            <div className="flex:wrapper m:width-20 tb:width-17 mv:4">
                <Nav className="flex align:center justify:around">
                    <Link href="#inicio">inicio</Link>
                    <Link href="#nosotros">nosotros</Link>
                    <Link href="#servicios">servicios</Link>
                    <Link href="#contacto">contacto</Link>
                </Nav>
            </div>
        </div>
    );
};

export default Navigation;
